import * as React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import SSRProvider from 'react-bootstrap/SSRProvider';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";

import Footer from '../../../../components/footer'
import GdprPanel from '../../../../components/gdpr'
import IPC20210416Panels from '../../../../components/ipc/ipc2021_04162021_Panels'
import IPC20210417Panels from '../../../../components/ipc/ipc2021_04172021_Panels'
import IPC20210418Panels from '../../../../components/ipc/ipc2021_04182021_Panels'
import IPC2021Panelists from '../../../../components/ipc/ipc2021Panelists'
import IpcContactForm from '../../../../components/forms/ipcContactForm'
import IpcHeader from '../../../../components/ipc/ipc-header'
import Navigation from '../../../../components/navigation'
import Seo from '../../../../components/seo'
import SubNavigation from "../../../../components/subNav";
import NewsletterSignup from "../../../../components/newsletterSignUp";

const Page = ({ data }) => {

    const shortcodes = {
        Row,
        Col,
    }

    return (      
        <>  
        <SSRProvider>
            <Navigation />
            <IpcHeader headline={data.mdx.frontmatter.headline} heroBackground={data.mdx.frontmatter.heroBackground} heroMobileBackground={data.mdx.frontmatter.heroMobileBackground} subHeadline={data.mdx.frontmatter.subheadline} eventDate={data.mdx.frontmatter.subtitle} />
            <SubNavigation chevron="true" subNav={data.mdx.frontmatter.subNav} /> 
            <div id="body">
                <div id="saveTheDate">
                    <Container fluid="lg">
                        <Row className=" py-4 px-md-0 px-4 justify-content-around align-items-center">
                            <Col xs={12} md={8}>
                                <h4 className="mb-3"><strong>Save the Date for the 2022 Integral Practitioner Convergence</strong></h4>
				                <p>The 5th Integral Practitioner Convergence is taking place May 6-8, 2022. This page includes the program from the 2021 Convergence.</p>
                            </Col>
                            <Col xs={12} md={3} className="p-3">
                                <Button href="#register" variant="primary">Sign Up to Stay Updated</Button>
                            </Col>
                        </Row>
                    </Container>                    
                </div>
                <Container fluid="lg">
                    <Row>
                        <Col lg={12} className="pe-5 mb-5">
                            <MDXProvider components={shortcodes}>
                                <MDXRenderer>{data.mdx.body}</MDXRenderer>
                            </MDXProvider>      
                        </Col>
                    </Row>
                </Container>
                <Container fluid>
                    <Row className="px-0">
                        <Col md={6} className="p-0">
                            <img src="https://web-cdn.meridianuniversity.edu/site-content-images/ipc-2021-new-img-a.jpg" className="img-fluid" alt="integral practitioners discuss at a table" />
                        </Col>
                        <Col md={6} className="p-0">
                            <img src="https://web-cdn.meridianuniversity.edu/site-content-images/ipc-2021-new-img-b.jpg" className="img-fluid" alt="integral practitioners sit outside during a convergence" />
                        </Col>
                    </Row>
                </Container>
                <div className="green-bg">
                    <Container fluid="lg">
                        <Row className="quote align-items-center py-3 py-lg-5">
                            <Col lg={3} className="order-lg-last mb-3 mb-md-0 text-center">
                                <img src="https://web-cdn.meridianuniversity.edu/ipc-2021-headshots/walsh-ipc-headshot.png" alt="roger-walsh-headshot" title="Roger Walsh" className="img-rounded img-fluid" />
                            </Col>
                            <Col lg={9} className="order-lg-first p-4 text-center">
                                <p>"No matter how bad our crises, our responses will decide both their consequences and our destiny. If that’s so, and it is, it raises one of the great questions of our time and of all time: How can we contribute most effectively to help mitigate or heal these crises?<br /><br />As integral practitioners, this question becomes: How can we use our integral skills to contribute most effectively? Of course, beneath this lies another question: How do we discover our most effective contributions?"</p>
                                <p className="author">—  Roger Walsh, MD, PhD<br />From "Calling All Integral Practitioners" -2021</p>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div id="whatArePractitioners">
                    <Container fluid="lg">
                        <Row>
                            <Col lg={12} className="pe-5 mb-5">
                                <Row className="white-bg my-lg-5 my-3">
                                    <Col className="p-4">
                                        <h1>What are Integral Practitioners?</h1>
                                        <p>Integral practitioners foster the development and transformation of individuals, teams, organizations, communities, and societies by empowering the whole person and the whole system. In domains such as <b>education, business, healing, coaching,</b> and the <b>arts</b>, integral practitioners bring imagination to complex challenges in the service of transforming mindsets, cultures and social systems.</p>
                                        <p>Integral Practitioners catalyze development in multiple domains of professional practice through facilitating transformative learning at different levels of systems: From individuals, and all the way up to societies.</p>
                                        <Row>
                                            <Col md={6} className="order-last order-md-first pe-1">
                                                <h3><strong>Integral practitioners make an impact by:</strong></h3>
                                                <ul>
                                                    <li><FontAwesomeIcon icon={faChevronCircleRight} color="#0078ae"/> Facilitating incremental, adaptive and transformative change.</li>
                                                    <li><FontAwesomeIcon icon={faChevronCircleRight} color="#0078ae"/> Catalyzing development through transformative learning.</li>
                                                    <li><FontAwesomeIcon icon={faChevronCircleRight} color="#0078ae"/> Engaging the vitality and emergence of development and evolution.</li>
                                                    <li><FontAwesomeIcon icon={faChevronCircleRight} color="#0078ae"/> Leading in ways that take responsibility for the whole.</li>
                                                    <li><FontAwesomeIcon icon={faChevronCircleRight} color="#0078ae"/> Evolving new methods of creative collaboration.</li>
                                                    <li><FontAwesomeIcon icon={faChevronCircleRight} color="#0078ae"/> Securing the function of integrity in integral practice.</li>
                                                    <li><FontAwesomeIcon icon={faChevronCircleRight} color="#0078ae"/> Developing the whole person: cognitively, emotionally, somatically, and spiritually.</li>
                                                    <li><FontAwesomeIcon icon={faChevronCircleRight} color="#0078ae"/> Integrating action and reflection through collective action inquiry and learning.</li>
                                                    <li><FontAwesomeIcon icon={faChevronCircleRight} color="#0078ae"/> Anchoring the sacred by reuniting the artistic and the scientific.</li>
                                                    <li><FontAwesomeIcon icon={faChevronCircleRight} color="#0078ae"/> Innovating prototypes that can be scaled while sustaining beauty and practicality.</li>
                                                    <li><FontAwesomeIcon icon={faChevronCircleRight} color="#0078ae"/> Inhabiting both the contemplative and the performative.</li>
                                                    <li><FontAwesomeIcon icon={faChevronCircleRight} color="#0078ae"/> Inquiring through both expressive and scientific methods.</li>
                                                    <li><FontAwesomeIcon icon={faChevronCircleRight} color="#0078ae"/> Healing division within historically wounded systems of relationships.</li>
                                                </ul>
                                            </Col>
                                            <Col md={6} className="order-first order-md-last text-center">
                                                <img src="https://web-cdn.meridianuniversity.edu/site-thumbs/tl-domains-w-bg.png" className="img-fluid" alt="TL domains"/>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="green-bg">
                    <Container fluid="lg">
                        <Row className="quote align-items-center py-3 py-lg-5">
                            <Col lg={6} className="text-start border-bottom border-end">
                                <Row className="justify-content-center">
                                    <Col lg={8} className="order-first order-lg-last">
                                        <p>"The ultimate, most sacred form of theory is practice."</p>
                                        <p className="author">— Nikos Kazantzakis</p>
                                    </Col>
                                    <Col lg={4} className="mb-3 mb-md-0 text-start">
                                        <img src="https://web-cdn.meridianuniversity.edu/header-images/Nikos_Kazantzakis_Edited.png" alt="nikos-kazantzakis-headshot" title="Nikos Kazantzakis" />
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={6} className="text-end">
                                <Row className="justify-content-center">
                                    <Col lg={8}>
                                        <p>"There is nothing so practical as a good theory."</p>
                                        <p className="author">— Kurt Lewin</p>
                                    </Col>
                                    <Col lg={4} className="mb-md-0 text-end">
                                        <img src="https://web-cdn.meridianuniversity.edu/header-images/kurt-lewin-edit.jpg" alt="kurt-lewin-headshot" title="Kurt Lewin" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container fluid>
                    <Row className="px-0">
                        <Col md={6} className="p-0">
                            <img src="https://web-cdn.meridianuniversity.edu/site-content-images/ipc-2021-new-img-c.jpg" className="img-fluid" alt="integral practitioners discussing outside on benches" />
                        </Col>
                        <Col md={6} className="p-0">
                            <img src="https://web-cdn.meridianuniversity.edu/site-content-images/ipc-2021-new-img-d.jpg" alt="integral practitioner gather at a convergence" className="img-fluid" />
                        </Col>
                    </Row>
                </Container>
                <div className="green-bg">
                    <Container fluid="lg">
                        <Row className="quote align-items-center py-3 py-lg-5">
                            <Col lg={2} className="mb-3 mb-md-0">
                                <img src="https://web-cdn.meridianuniversity.edu/header-images/donella-meadows-edit.jpg" alt="donella-meadows" title="Donella Meadows" />
                            </Col>
                            <Col lg={9} className="text-lg-start p-4 text-center">
                                <p>"You could say paradigms are harder to change than anything else about a system…. But there’s nothing physical or expensive or even slow in the process of paradigm change. In a single individual it can happen in a millisecond. All it takes is a click in the mind, a falling of scales from eyes, a new way of seeing."</p>
                                <p className="author">— Donella Meadows</p>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div id="theConvergence">
                    <Container fluid="lg">
                        <Row>
                            <Col lg={12} className="pe-5 mb-5">
                                <Row className="white-bg my-lg-5 my-3">
                                    <Col className="p-4">
                                        <h1>The 2021 Convergence: Connect, Collaborate, Create</h1>
                                        <h3 className="mb-3"><strong>Calling Coaches, Entrepreneurs, Innovators, Consultants, Changemakers, and Leaders...</strong></h3>
                                        <p>In past years, the Integral Practitioner Convergence has taken place in Berlin and Vienna while Integral Practitioner Labs have been convened in Vienna, Berlin, Boston, Oakland, and San Francisco.</p>
                                        <p>Now, integral practitioners from around the world are converging online on Friday, Saturday, and Sunday, April 16th, 17th, and 18th to learn from each other about the capabilities, methods, models and practices that they are innovating in the context of their professional engagements.</p>
                                        <h3 className="mb-3"><strong>Who should participate?</strong></h3>
                                        <p>Participants will include aspiring, early career, mid-career, established, and distinguished Integral Practitioners in the domains of <b>education, coaching, organizational leadership, business, and well-being</b> &amp; <b>healthcare</b>.</p>
                                        <h3><strong>What will Convergence participants do?</strong></h3>
                                        <ul>
                                            <li><FontAwesomeIcon icon={faChevronCircleRight} color="#0078ae"/> Experience a diverse community of inquiry and practice.</li>
                                            <li><FontAwesomeIcon icon={faChevronCircleRight} color="#0078ae"/> Engage with established Integral Practitioners from around the world via live video regarding their professional work and learning journeys.</li>
                                            <li><FontAwesomeIcon icon={faChevronCircleRight} color="#0078ae"/> Connect with other aspiring and emerging practitioners.</li>
                                            <li><FontAwesomeIcon icon={faChevronCircleRight} color="#0078ae"/> Collaborate with others in prototyping your practice idea.</li>
                                            <li><FontAwesomeIcon icon={faChevronCircleRight} color="#0078ae"/> Discover the distinct web of competencies required for realizing your unique vision of integral practice.</li>
                                            <li><FontAwesomeIcon icon={faChevronCircleRight} color="#0078ae"/> Explore the core consciousness capacities vital for Integral Practitioners.</li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="green-bg">
                    <IPC2021Panelists />
                </div>
                <div id="panels">
                    <Container fluid="lg">
                        <Row>
                            <Col lg={12} className="pe-5 mb-5">
                                <Row className="white-bg my-lg-5 my-3">
                                    <Col className="p-4">
                                        <h1>Convergence Program</h1>
                                        <p>Convergence panel topics and themes planned for April 16, 17, and 18 include:</p>
                                        <IPC20210416Panels />
                                        <IPC20210417Panels />
                                        <IPC20210418Panels />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div id="register">
                <IpcContactForm submitCampaignID="7013i000000qFt0AAE" submitType="contact" />
            </div>
            <Footer />
            <GdprPanel />
            <NewsletterSignup />
        </SSRProvider>
        </>
    )
}

export const Head = ({data}) => (
    <Seo 
        title={data.mdx.frontmatter.metadata.Title} 
        description={data.mdx.frontmatter.metadata.Description} 
        pathname={data.mdx.frontmatter.metadata.Link}
        ogImage={data.mdx.frontmatter.metadata.ogImage}
        cardImage={data.mdx.frontmatter.metadata.cardImage} 
    >
      <style type="text/css">
        {`
              #saveTheDate { background: #fec311; }
              .green-bg { background: #8bb17c; color: white;}
              .quote p { font-size: 22px;line-height: 165%; }
              .quote img { flex-shrink: 0; width: 165px; height: 165px; overflow: hidden; margin-bottom: 10px; border-radius: 100%; }
              .quote .author { margin-top: 20px; font-family: 'Source Sans Pro'; font-size: 14px; font-weight: 700; text-transform: uppercase; letter-spacing: .12em; }
              @media screen and (min-width:992px) {
                  .quote .border-bottom { border-bottom: 0px !important; }
              }
              @media screen and (max-width:991.98px) {
                  .quote .border-end { border-right: 0px !important; }
              }
          `}
      </style>
    </Seo>
  )

export const query = graphql`
    query IPC2021PageQuery {
        mdx(fileAbsolutePath: {regex: "/integral-practitioner-convergence-2021.mdx/"})  {
            frontmatter {
                heroBackground
                heroMobileBackground
                subheadline
                headline
                title
                subtitle
                metadata {
                    Description
                    Keywords
                    Link
                    Title
                    ogImage
                    cardImage
                }
                subNav {
                    text
                    url
                }

            }
            body
        }
    }    
`

export default Page